import revive_payload_client_VzsEXfaHiQ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.6.2_vite@5.4.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uQTXYz0uPA from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.6.2_vite@5.4.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_eTwETSfgQY from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.6.2_vite@5.4.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_kABAwQyQRm from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.17_rollup@4.21.2_vite@5.4.4_vue@3.5.4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_oianqDY2xm from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.6.2_vite@5.4.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ligx3Sib06 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.6.2_vite@5.4.4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_26LnElp4pS from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.6.2_vite@5.4.4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_qSjbRuBITb from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.6.2_vite@5.4.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_I1nffnwQhV from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.21.2_typescript@5.6.2_vue@3.5.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_L9VlLaUDEH from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.6.2_vite@5.4.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_6d47cc20_KQmmYtgkoG from "/opt/build/repo/.nuxt/templates.pwa.client.6d47cc20.ts";
import plugin_fJlDd9k4l1 from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.13.1_rollup@4.21.2_vue@3.5.4/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_t9oejpngDf from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_rollup@4.21.2_typescript@5.6.2_vue@3.5.4/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_gEPIfivAns from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.21.2_vue@3.5.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sbsVlI6A5L from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.21.2_vue@3.5.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import animation_directives_client_BcJ9PYvc9H from "/opt/build/repo/plugins/animation-directives.client.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import leaflet_client_trb2AG60Fs from "/opt/build/repo/plugins/leaflet.client.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
export default [
  revive_payload_client_VzsEXfaHiQ,
  unhead_uQTXYz0uPA,
  router_eTwETSfgQY,
  _0_siteConfig_kABAwQyQRm,
  payload_client_oianqDY2xm,
  navigation_repaint_client_ligx3Sib06,
  check_outdated_build_client_26LnElp4pS,
  chunk_reload_client_qSjbRuBITb,
  plugin_vue3_I1nffnwQhV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_L9VlLaUDEH,
  templates_pwa_client_6d47cc20_KQmmYtgkoG,
  plugin_fJlDd9k4l1,
  plugin_t9oejpngDf,
  switch_locale_path_ssr_gEPIfivAns,
  i18n_sbsVlI6A5L,
  animation_directives_client_BcJ9PYvc9H,
  apollo_IRWTEOPleP,
  leaflet_client_trb2AG60Fs,
  sentry_client_GoGQuZo4Et
]